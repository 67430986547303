* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "NunitoSans";
}
@font-face {
  font-family: "NunitoSans";
  src: url(../fonts/NunitoSans.ttf);
}
:root {
  --primary: #155ad8;
}
html {
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
  color: #000;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body.dark {
  background: #000;
}
h1 {
  margin: 0;
  font-size: 1.5rem;
}
h2 {
  margin: 0;
  font-size: 1.25rem;
}
p {
  margin: 0;
}
svg {
  font-size: 1.5rem;
}
ul {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.menu-item a {
  color: #000 !important;
}
.primary-text {
  color: var(--primary);
}
.auth-title-box {
  background: var(--primary);
  padding: 8px;
  text-align: center;
  color: #fff;
}
.rounded {
  border-radius: 4px !important;
}
.table-container {
  width: 100%;
  overflow: auto;
}
.table-container table {
  min-width: 1000px;
}
.creditorArrow,
.EMIArrow,
.indexArrow {
  cursor: pointer;
}

.creditorArrow.rotate,
.EMIArrow.rotate,
.indexArrow.rotate {
  transform: rotate(180deg);
}
header a.active {
  background: #00000025;
  border-radius: 4px;
}
.password-div {
  width: 100%;
  position: relative;
}
.password-show {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
}
.password-show * {
  pointer-events: none;
}
.table-background {
  background: #f0f0f0;
}
.count-badge {
  border-radius: 4px;
  background: transparent;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
}
.count-badge.active {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
}
.top-bar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.toggle-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.creditScoreFile {
  max-width: 50px;
  height: 50px;
  cursor: pointer;
}
.creditScoreFile.active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  max-width: 300px;
  height: auto;
}
.user-profile-grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.user-profile-pic {
  width: 200px;
  height: 200px;
}
.user-profile-pic img,
.user-profile-display img {
  border-radius: 50%;
}
.user-profile-display {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
}
.user-profile-display * {
  cursor: pointer;
}
.user-profile-display input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}
.user-profile-display .edit-profile-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.edit-profile-button svg {
  text-shadow: 0 0 10px 5px #000;
}
.text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.logo {
  width: 100px;
}
