@media screen and (max-width: 768px) {
  .user-profile-grid {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .menu-item {
    position: relative;
  }
  .top-bar-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .top-bar-grid > div:nth-child(1) {
    flex: 1;
  }
}
@media screen and (max-width: 400px) {
  .toggle-buttons {
    flex-direction: column;
    align-items: start;
  }
}
